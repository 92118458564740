import React, { memo, useState } from 'react';

import { useRouter } from 'next/router';
import { DropdownItem } from 'components/forms/types';
import Dropdown from 'components/forms/dropdown';

import { getLocalizedPath } from 'src/url-manager';
import useCarsApi from 'providers/cars-api/use-cars-api';
import CountryFlag from 'components/common/country-flag';
import styles from './header.module.scss';

const LanguageSelect: React.FC = () => {
  const locales = [
    { lang: 'de', locale: 'de_DE', label: 'Deutsch' },
    { lang: 'en', locale: 'en_GB', label: 'English' },
    { lang: 'en', locale: 'en_US', label: 'English (International)' },
    { lang: 'fr', locale: 'fr_FR', label: 'Français' },
    { lang: 'pl', locale: 'pl_PL', label: 'Polski' },
    {}
  ];

  const { agent } = useCarsApi();
  const router = useRouter();
  const [locale] = useState(router.locale || router.defaultLocale);

  if ((agent?.availableLanguages ?? []).length < 1) {
    return null;
  }

  if (
    [
      'anakonda',
      'mwaffiliate13',
      'mwaffiliate14',
      'mwaffiliate15',
      'mwaffiliate16',
      'mwaffiliate17',
      'mwaffiliate18',
      'specials993194'
    ].includes(agent?.agentId)
  ) {
    locales.push(
      { lang: 'cs', locale: 'cs_CZ', label: 'Čeština' },
      { lang: 'es', locale: 'es_ES', label: 'Español' },
      { lang: 'it', locale: 'it_IT', label: 'Italiano' },
      { lang: 'ru', locale: 'ru_RU', label: 'Русский' },
      { lang: 'tr', locale: 'tr_TR', label: 'Türk' }
    );
  }

  const langChange = (item) => {
    window.location.href = getLocalizedPath(router.asPath, item.value);
  };

  const localeToCountry = (loc) => loc.slice(-2).toUpperCase();

  const allowedLocales = locales.filter(
    (item) => agent.availableLanguages.indexOf(item.locale) > -1
  );
  const options: Array<DropdownItem> = allowedLocales.map((item) => ({
    value: item.locale,
    label: (
      <div className={styles.languageSelect__row}>
        <CountryFlag
          className={styles.languageSelect__value}
          countryCode={localeToCountry(item.locale)}
          size="20px"
        />
        <span>{item.label}</span>
      </div>
    ),
    valueLabel: (
      <CountryFlag
        className={styles.languageSelect__value}
        countryCode={localeToCountry(item.locale)}
      />
    )
  }));

  return (
    <Dropdown
      options={options}
      value={options.find((option) => option.value === locale)}
      onChange={langChange}
      noEmptyOption
      horizontal
      noBorder
      optionsPosition="right"
    />
  );
};

export default memo(LanguageSelect);
